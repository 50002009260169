.main-sidebar {
	ul {
		list-style: none;
		padding-left: 20px;

		li {
			margin-bottom: 8px;
		}
	}

	a {
		color: $link-color;
	}
	
	.children-toggle {
		display: inline-block;
		border-top: 1px solid black;
		border-right: 1px solid black;
		transform: rotate(45deg);
		width: 10px;
		height: 10px;
		margin-left: 3px;
		transition: all 250ms;
		cursor: pointer;
		position: relative;

		&.opened {
			transform: rotate(134deg);
			top: -4px;
			left: 3px;
		}
	}

	.children {
		display: none;
	}

	.current-cat {
		background-color: $light-gray;

		.children {
			background-color: white;
		}
	}
}
.js-menu-expanded {

  @media screen and (max-width: em($mobile-menu-switch-max)) {
    overflow-y: scroll;
    body {
      overflow: hidden;
      height: 100%;
    }
  }
}


.site-menu {

  @media screen and (max-width: em($mobile-menu-switch-max)) {
    height: 0vh;
    transition: height 0.35s ease-out;

    .js-menu-expanded & {
      width: calc(100% + 50px);
      height: calc(100vh - #{$mobile-header-height});
      overflow: {
        x: hidden;
        y: auto;
      }
      padding-right: 50px;
    }
  }
}


.main-header {

  @media screen and (max-width: em($mobile-menu-switch-max)) {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    transition: background-color 0.35s linear;

    .js-menu-expanded & {
      background-color: $main-header-background-color;

      .wrapper {
        overflow: hidden;
      }
    }

    & ~ main {
      padding-top: $mobile-header-height;
    }
  }

  @media (max-width:em(400px)){
    & ~ main {
      padding-top: 70px;
    }
  }
}


.main-menu {

  @media screen and (max-width: em($mobile-menu-switch-max)) {
    opacity: 0;
    padding: {
      top: 10px;
      bottom: 44px;
    }
    overflow: hidden;
    transition: opacity 0.35s linear;

    .js-menu-expanded & {
      opacity: 1;
    }
  }

  .menu-item {

    @media screen and (max-width: em($mobile-menu-switch-max)) {
      opacity: 0;
      transform: scale(1.1) translateY(-100%);
      transition: opacity 0.35s ease-out, transform 0.35s ease-out;

      @for $i from 1 through $menu-items {
        &:nth-child(#{$i}) {
          transition-delay: #{(($i * 0.5) * 100) + 250}ms, #{(($i * 0.5) * 100) + 250}ms;
        }
      }

      .js-menu-expanded & {
        opacity: 1;
        transform: scale(1) translateY(0);
      }
    }
  }
  a {
    display: block;
    vertical-align: middle;
    line-height: 0.8;
    text: {
      decoration: none;
      align: left;
    }
    padding: 16px;

    @media screen and (max-width: em($mobile-menu-switch-max)) {
      border-bottom: solid 1px rgba($main-menu-item-color, 0.25);
    }
  }
}

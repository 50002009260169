%fluid-image {
  width: auto;
  max-width: 100%;
  height: auto;
}
img[src*=svg] {
  width: 100%\9;

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    width: 100%;
  }
}

%hidden {
  display: none;
}

%visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

%invisible {
  visibility: hidden;
}

%clearfix {

  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

%background-cover {
  background-size: cover;
  background-position: center;
}

.clearfix {
  @extend %clearfix;
}

.background-cover {
  @extend %background-cover;
}
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,600,700");
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

button,
input,
select,
textarea {
  font: inherit;
  margin: 0;
}

optgroup {
  font-weight: bold;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

textarea {
  overflow: auto;
}

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

/**
 *  HEADER 
 **/
/**
 *  FOOTER 
 **/
/** 
 *  BUTTONS
 **/
img[src*=svg] {
  width: 100%\9;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  img[src*=svg] {
    width: 100%;
  }
}

.mobile-menu-button {
  display: none;
}

.clearfix:before, .horizontal-scroll-menu:before, .content-editor blockquote:before, .content-editor:before, .clearfix:after, .horizontal-scroll-menu:after, .content-editor blockquote:after, .content-editor:after {
  content: ' ';
  display: table;
}

.clearfix:after, .horizontal-scroll-menu:after, .content-editor blockquote:after, .content-editor:after {
  clear: both;
}

.background-cover, .block-section-background-image, input[type=checkbox]:checked, .page-template-page-manufacturers .block-section-flex {
  background-size: cover;
  background-position: center;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none !important;
  -webkit-tap-highlight-color: transparent;
}

.xdebug-var-dump {
  background: white !important;
  color: black !important;
}

html,
body {
  height: 100%;
  position: relative;
}

body {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.625;
  color: black;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  justify-content: space-between;
}

body .footer-push {
  width: 100%;
  align-self: flex-start;
}

body footer {
  width: 100%;
  align-self: flex-end;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

h1 {
  font-weight: 600;
  font-size: 1.5555555556em;
  text-transform: uppercase;
  letter-spacing: 1px;
}

h2 {
  font-weight: 600;
  font-size: 28px;
}

h3 {
  font-weight: 600;
  color: #ba0000;
  text-transform: uppercase;
  font-size: 1em;
}

h4 {
  font-size: 22px;
  font-weight: 600;
}

img {
  max-width: 100%;
  height: auto;
}

ul,
ol {
  list-style-position: inside;
}

.loading {
  width: 22px;
  height: 22px;
  background-image: url(../../images/icons/loading.gif);
  display: inline-block;
}

.wrapper {
  position: relative;
  max-width: 1170px;
  margin: 0 auto;
  width: 91%;
}

.block-section {
  padding: 40px 0;
}

.block-section-background-image {
  background-attachment: fixed;
}

.block-section-valign-center .wrapper {
  align-items: center;
}

.block-section-gray {
  background: #8c8c8c;
  color: white;
}

.block-section-1-3 .wrapper {
  display: flex;
  justify-content: space-between;
}

.block-section-1-3 .wrapper .left {
  flex: 1;
  margin: 0 40px 20px 0;
}

.block-section-1-3 .wrapper .right {
  flex: 3;
}

.block-section-3-1 .wrapper {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 48em) {
  .block-section-3-1 .wrapper {
    display: block;
  }
}

.block-section-3-1 .wrapper .left {
  flex: 3;
  margin: 0 40px 20px 0;
}

@media (max-width: 48em) {
  .block-section-3-1 .wrapper .left {
    margin: 0 0 20px 0;
  }
}

.block-section-3-1 .wrapper .right {
  flex: 1;
}

.block-section-flex .wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.columns {
  display: flex;
  justify-content: space-between;
}

.columns > *:not(.column) {
  display: none !important;
}

@media (max-width: 48em) {
  .columns {
    display: block;
  }
}

.columns .column {
  width: 48%;
  margin-top: 0 !important;
}

@media (max-width: 48em) {
  .columns .column {
    width: 100%;
  }
}

@media (max-width: 48em) {
  .columns .column:first-of-type {
    margin-bottom: 40px;
  }
}

.blog .post,
.archive .post {
  display: flex;
  align-items: flex-start;
}

@media (max-width: 48em) {
  .blog .post,
  .archive .post {
    display: block;
  }
}

.blog .post .wp-post-image,
.archive .post .wp-post-image {
  max-width: 175px;
  display: block;
  margin: 0 auto;
}

@media (max-width: 48em) {
  .blog .post .wp-post-image,
  .archive .post .wp-post-image {
    max-width: 100%;
  }
}

.blog .post .wp-post-image + .post-content,
.archive .post .wp-post-image + .post-content {
  margin-left: 20px;
}

.single-product-wrapper a,
.woocommerce-cart-form a:not(.remove),
.link {
  box-shadow: 0 0 0 0 #ba0000;
  color: #ba0000;
  text-decoration: none;
  transition: color 0.1s ease, box-shadow 0.1s ease;
}

.single-product-wrapper a:hover, .single-product-wrapper a:focus,
.woocommerce-cart-form a:not(.remove):hover,
.woocommerce-cart-form a:not(.remove):focus,
.link:hover,
.link:focus {
  color: #820000;
  box-shadow: 0 1px 0 0 #820000;
}

.woocommerce-breadcrumb {
  color: #ba0000 !important;
  font-size: 0.8em !important;
}

.woocommerce-breadcrumb a {
  color: #ba0000 !important;
  text-decoration: none;
}

.woocommerce-breadcrumb a:hover {
  color: #820000;
  text-decoration: underline;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}

.woocommerce .button,
.woocommerce .button.alt,
.woocommerce div.product form.cart .button,
.woocommerce #respond input#submit.alt,
.woocommerce a.button.alt,
.woocommerce button.button.alt,
.woocommerce input.button.alt,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
.woocommerce #respond input#submit.disabled,
.woocommerce #respond input#submit:disabled,
.woocommerce #respond input#submit:disabled[disabled],
.woocommerce a.button.disabled, .woocommerce a.button:disabled,
.woocommerce a.button:disabled[disabled],
.woocommerce button.button.disabled,
.woocommerce button.button:disabled,
.woocommerce button.button:disabled[disabled],
.woocommerce input.button.disabled,
.woocommerce input.button:disabled,
.woocommerce input.button:disabled[disabled],
.button {
  border: 1px solid black;
  color: #ba0000;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  transition: all 250ms;
  background: white;
  cursor: pointer;
  box-shadow: none;
  line-height: 1;
  border-radius: 0;
  font-size: 18px;
  padding: 8px 21px 5px;
}

.woocommerce .button:hover,
.woocommerce .button.alt:hover,
.woocommerce div.product form.cart .button:hover,
.woocommerce #respond input#submit.alt:hover,
.woocommerce a.button.alt:hover,
.woocommerce button.button.alt:hover,
.woocommerce input.button.alt:hover,
.woocommerce #respond input#submit:hover,
.woocommerce a.button:hover,
.woocommerce button.button:hover,
.woocommerce input.button:hover,
.woocommerce #respond input#submit.disabled:hover,
.woocommerce #respond input#submit:disabled:hover,
.woocommerce #respond input#submit:disabled[disabled]:hover,
.woocommerce a.button.disabled:hover, .woocommerce a.button:disabled:hover,
.woocommerce a.button:disabled[disabled]:hover,
.woocommerce button.button.disabled:hover,
.woocommerce button.button:disabled:hover,
.woocommerce button.button:disabled[disabled]:hover,
.woocommerce input.button.disabled:hover,
.woocommerce input.button:disabled:hover,
.woocommerce input.button:disabled[disabled]:hover,
.button:hover {
  background: #ba0000;
  color: white;
}

.woocommerce .button:active,
.woocommerce .button.alt:active,
.woocommerce div.product form.cart .button:active,
.woocommerce #respond input#submit.alt:active,
.woocommerce a.button.alt:active,
.woocommerce button.button.alt:active,
.woocommerce input.button.alt:active,
.woocommerce #respond input#submit:active,
.woocommerce a.button:active,
.woocommerce button.button:active,
.woocommerce input.button:active,
.woocommerce #respond input#submit.disabled:active,
.woocommerce #respond input#submit:disabled:active,
.woocommerce #respond input#submit:disabled[disabled]:active,
.woocommerce a.button.disabled:active, .woocommerce a.button:disabled:active,
.woocommerce a.button:disabled[disabled]:active,
.woocommerce button.button.disabled:active,
.woocommerce button.button:disabled:active,
.woocommerce button.button:disabled[disabled]:active,
.woocommerce input.button.disabled:active,
.woocommerce input.button:disabled:active,
.woocommerce input.button:disabled[disabled]:active,
.button:active {
  background: #870000;
}

.button-small {
  font-size: 14px !important;
  padding: 4px 11px !important;
}

.woocommerce #respond input#submit.disabled:hover,
.woocommerce #respond input#submit:disabled:hover,
.woocommerce #respond input#submit:disabled[disabled]:hover,
.woocommerce a.button.disabled:hover, .woocommerce a.button:disabled:hover,
.woocommerce a.button:disabled[disabled]:hover,
.woocommerce button.button.disabled:hover,
.woocommerce button.button:disabled:hover,
.woocommerce button.button:disabled[disabled]:hover,
.woocommerce input.button.disabled:hover,
.woocommerce input.button:disabled:hover,
.woocommerce input.button:disabled[disabled]:hover {
  cursor: not-allowed;
  background: white;
  color: #ba0000;
}

@keyframes fadeIn {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    position: absolute;
    visibility: hidden;
  }
}

@keyframes fallIn {
  0% {
    transform: translateY(-80px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes zoomLeft {
  0% {
    transform: translateX(-80px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes zoomRight {
  0% {
    transform: translateX(80px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes zoomOutLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  99% {
    transform: translateX(-80px);
    opacity: 0;
  }
  100% {
    position: absolute;
    visibility: hidden;
  }
}

@keyframes zoomOutRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  99% {
    transform: translateX(80px);
    opacity: 0;
  }
  100% {
    position: absolute;
    visibility: hidden;
  }
}

@keyframes scaleToFull {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes scaleToNone {
  0% {
    transform: scale(1);
  }
  99% {
    transform: scale(0);
  }
  100% {
    position: absolute;
    visibility: hidden;
  }
}

.fade-in {
  animation: fadeIn 500ms ease-out;
  animation-fill-mode: forwards;
}

.fade-out {
  animation: fadeOut 500ms ease-out;
  animation-fill-mode: forwards;
}

.fall-in {
  animation: fallIn 500ms ease-out;
  animation-fill-mode: forwards;
}

.zoom-in-left {
  animation: zoomLeft 500ms ease-out;
  animation-fill-mode: forwards;
}

.zoom-in-right {
  animation: zoomRight 500ms ease-out;
  animation-fill-mode: forwards;
}

.zoom-out-left {
  animation: zoomOutLeft 500ms ease-out;
  animation-fill-mode: forwards;
}

.zoom-out-right {
  animation: zoomOutRight 500ms ease-out;
  animation-fill-mode: forwards;
}

.scale-to-full {
  animation: scaleToFull 500ms ease-out;
  animation-fill-mode: forwards;
}

.scale-to-none {
  animation: scaleToNone 500ms ease-out;
  animation-fill-mode: forwards;
}

.page-headline {
  font-size: 1.5rem;
}

@media (min-width: 20em) and (max-width: 73.125em) {
  .page-headline {
    font-size: calc(1.5rem + (2.625 - 1.5) * (100vw - 20rem) / (73.125 - 20));
  }
}

@media (min-width: 73.125em) {
  .page-headline {
    font-size: 2.625rem;
  }
}

.title-hr {
  text-align: center;
  position: relative;
  font-weight: 600;
  font-size: 1.5555555556em;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
}

.title-hr .word {
  margin: 0 10px;
}

.title-hr .line {
  height: 2px;
  background: #ba0000;
  flex: 1;
}

.main-header {
  background-color: white;
}

@media (max-width: 48em) {
  .main-header {
    border-bottom: 1px solid black;
  }
}

@media (max-width: 25em) {
  .main-header {
    min-height: 70px;
  }
}

.header-top-wrapper {
  display: flex;
  padding: 10px 0;
  color: #ba0000;
}

.header-top-wrapper a {
  color: #ba0000;
  text-decoration: none;
}

@media (max-width: 48em) {
  .header-top-wrapper .site-logo {
    margin-left: 30px;
  }
}

.header-top-wrapper .site-logo img {
  height: 51px;
  width: 327px;
}

@media (max-width: 25em) {
  .header-top-wrapper .site-logo img {
    max-width: 100%;
    height: auto;
  }
}

.header-top-wrapper .site-logo a {
  position: relative;
  display: inline-block;
}

.header-top-wrapper .site-logo a:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: -1px;
  height: 1px;
  width: 0;
  background: transparent;
  transition: width 250ms ease, background-color 0ms ease;
}

@media (max-width: 48em) {
  .header-top-wrapper .site-logo a:after {
    display: none;
  }
}

.header-top-wrapper .site-logo a:hover:after {
  width: 100%;
  background: #ba0000;
}

.header-top-wrapper .text-under {
  font-size: 15px;
  letter-spacing: 0.115px;
  line-height: 1;
  padding-top: 4px;
}

@media (max-width: 25em) {
  .header-top-wrapper .text-under {
    display: none;
  }
}

.header-top-wrapper .content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-left: 12px;
  padding-left: 12px;
  border-left: 2px solid #ba0000;
  font-size: 1em;
}

@media (max-width: 48em) {
  .header-top-wrapper .content-container {
    display: none;
  }
}

.main-footer {
  height: auto;
  background-color: white;
  box-shadow: -1px 0px 50px -20px;
}

.main-footer a:not(.button) {
  color: white !important;
}

.footer-top {
  padding: 40px 0;
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: contain;
}

@media (max-width: 48em) {
  .footer-top {
    background-image: url() !important;
  }
}

.footer-top .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

@media (max-width: 48em) {
  .footer-top .wrapper {
    display: block;
  }
}

.footer-top .column {
  flex: 1;
}

.footer-top .column:nth-of-type(2) {
  flex: 2;
}

@media (max-width: 56.25em) {
  .footer-top .column:nth-of-type(3) {
    flex: 0;
  }
}

.footer-bottom {
  background-color: black;
  color: white;
  font-size: 0.625em;
}

.footer-bottom .wrapper {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 48em) {
  .footer-bottom .wrapper {
    display: block;
    text-align: center;
  }
  .footer-bottom .wrapper .content-editor {
    margin-bottom: 5px;
  }
}

.footer-bottom .wrapper .social {
  white-space: nowrap;
}

.footer-bottom img {
  margin-right: 8px;
}

.hero {
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 455px;
  position: relative;
  color: white;
  position: relative;
}

.hero:after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(28, 0, 0, 0.3);
}

@media (max-width: 48em) {
  .hero {
    height: 350px;
    background-attachment: scroll;
    background-size: cover;
    background-position: center;
  }
}

.hero.inner-hero {
  height: 225px;
  background-position: center;
  background-attachment: scroll;
}

.hero.no-image {
  height: auto;
  padding: 60px 0 0;
  color: black;
}

.hero.no-image .wrapper {
  position: static;
  transform: none;
}

.hero.no-image:after {
  display: none;
}

.hero .wrapper {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

@media (max-width: 62.5em) {
  .hero .wrapper > * {
    text-align: center !important;
  }
}

.hero h1 {
  font-weight: 600;
  font-size: 2.2222222222em;
  letter-spacing: 2px;
  line-height: 1em;
}

@media (max-width: 48em) {
  .hero h1 {
    font-size: 2em;
  }
}

.hero h2 {
  font-weight: normal;
  font-size: 1.8888888889em;
  line-height: 1.3em;
  letter-spacing: 2px;
}

@media (max-width: 48em) {
  .hero h2 {
    font-size: 1.75em;
  }
}

.hero a {
  color: #ba0000;
  text-decoration: none;
  transition: all 250ms;
}

.hero a:hover {
  color: #820000;
  text-decoration: underline;
}

.related.products h2 {
  width: 100%;
  margin-bottom: 20px;
}

.related.products .products {
  width: 100%;
}

.product-categories-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: baseline;
}

.product-categories-list .product-link {
  width: 15%;
  text-align: center;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  text-transform: uppercase;
}

@media (max-width: 76.5em) {
  .product-categories-list .product-link {
    width: 20%;
  }
}

@media (max-width: 59.125em) {
  .product-categories-list .product-link {
    width: 30%;
  }
}

@media (max-width: 48em) {
  .product-categories-list .product-link {
    width: 40%;
  }
}

@media (max-width: 30em) {
  .product-categories-list .product-link {
    width: 100%;
  }
}

.product-categories-list .product-link a {
  color: #ba0000;
  text-decoration: none;
  font-weight: 600;
}

.product-categories-list .product-link:hover {
  text-decoration: underline;
}

.product-categories-list .product-link img {
  display: block;
  margin: 0 auto 10px;
  max-height: 175px;
}

.product-list-full-details,
.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: baseline;
}

.product-list-full-details:before, .product-list-full-details:after,
.products:before,
.products:after {
  display: none !important;
}

.product-list-full-details .product-full-details,
.product-list-full-details .product,
.products .product-full-details,
.products .product {
  position: relative;
  width: 21% !important;
  margin: 0 0 40px 0 !important;
}

@media (max-width: 68.625em) {
  .product-list-full-details .product-full-details,
  .product-list-full-details .product,
  .products .product-full-details,
  .products .product {
    width: 26% !important;
  }
}

@media (max-width: 48em) {
  .product-list-full-details .product-full-details,
  .product-list-full-details .product,
  .products .product-full-details,
  .products .product {
    width: 44% !important;
  }
}

@media (max-width: 37.8125em) {
  .product-list-full-details .product-full-details,
  .product-list-full-details .product,
  .products .product-full-details,
  .products .product {
    width: 91% !important;
  }
}

.product-list-full-details .product-full-details:hover img,
.product-list-full-details .product:hover img,
.products .product-full-details:hover img,
.products .product:hover img {
  transform: scale(0.9);
}

.product-list-full-details .product-full-details:hover a:not(.button),
.product-list-full-details .product:hover a:not(.button),
.products .product-full-details:hover a:not(.button),
.products .product:hover a:not(.button) {
  color: white;
}

.product-list-full-details .product-full-details img,
.product-list-full-details .product img,
.products .product-full-details img,
.products .product img {
  max-width: 150px;
  max-height: 150px;
  width: auto;
  height: auto;
  transition: all 250ms;
  margin: 0 auto 10px !important;
  display: block;
  padding: 5px;
}

.product-list-full-details .product-full-details .product-content,
.product-list-full-details .product .product-content,
.products .product-full-details .product-content,
.products .product .product-content {
  background: black;
  padding: 4px 10px 20px;
  text-align: left;
}

.product-list-full-details .product-full-details .product-content .category,
.product-list-full-details .product .product-content .category,
.products .product-full-details .product-content .category,
.products .product .product-content .category {
  font-size: 0.857em;
}

.product-list-full-details .product-full-details .product-content .price,
.product-list-full-details .product .product-content .price,
.products .product-full-details .product-content .price,
.products .product .product-content .price {
  color: #77a464;
  font-size: 0.857em;
}

.product-list-full-details .product-full-details a:not(.button),
.product-list-full-details .product a:not(.button),
.products .product-full-details a:not(.button),
.products .product a:not(.button) {
  color: #8c8c8c;
  text-decoration: none;
  transition: all 250ms;
}

.product-list-full-details .product-full-details .button,
.product-list-full-details .product .button,
.products .product-full-details .button,
.products .product .button {
  position: absolute;
  right: 10px;
  transform: translateY(-50%);
  margin: 0 !important;
}

.product-list-full-details .product-full-details .button svg,
.product-list-full-details .product .button svg,
.products .product-full-details .button svg,
.products .product .button svg {
  width: 20px;
  height: 20px;
}

.manufacturers-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: baseline;
}

.manufacturers-list .manufacturer-link {
  width: 15%;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

@media (max-width: 76.5em) {
  .manufacturers-list .manufacturer-link {
    width: 20%;
  }
}

@media (max-width: 59.125em) {
  .manufacturers-list .manufacturer-link {
    width: 30%;
  }
}

@media (max-width: 48em) {
  .manufacturers-list .manufacturer-link {
    width: 40%;
  }
}

@media (max-width: 30em) {
  .manufacturers-list .manufacturer-link {
    width: 100%;
  }
}

.touch-mega-menu {
  display: none !important;
}

.touch-mega-menu.active {
  display: block !important;
}

.mega-menu {
  left: 0;
  width: 100%;
  color: white;
  z-index: 99 !important;
  padding: 10px;
}

.mega-menu .manufacturers-list,
.mega-menu .product-categories-list {
  background: white;
  text-align: center;
}

.mega-menu .manufacturers-list img,
.mega-menu .product-categories-list img {
  max-height: 125px;
}

.mega-menu .manufacturers-list a,
.mega-menu .product-categories-list a {
  border: 0;
  line-height: 1.4;
  white-space: normal;
  text-align: center;
}

/*
  General Inputs
*/
input[type=text],
input[type=password],
input[type=tel],
input[type=email],
textarea {
  padding: 3px;
  border-style: solid;
  border-width: 0;
}

input[type=checkbox] {
  appearance: none;
  border: 1px solid black;
  display: inline-block !important;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

input[type=checkbox]:checked {
  background-image: url(../../images/icons/x.svg);
}

/*
  Gravity Forms
*/
.gform_wrapper * {
  margin: 0 !important;
  padding: 0 !important;
}

.gform_wrapper .gform_heading {
  margin-bottom: 10px !important;
}

.gform_wrapper .gform_fields .gfield {
  margin-bottom: 10px !important;
}

.gform_wrapper .ginput_complex {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
}

@media (max-width: 32.1875em) {
  .gform_wrapper .ginput_complex {
    display: block;
  }
}

.gform_wrapper .ginput_complex > * {
  width: 48% !important;
}

@media (max-width: 32.1875em) {
  .gform_wrapper .ginput_complex > * {
    width: 100% !important;
  }
}

.gform_wrapper .gform_drop_area {
  padding: 24px !important;
}

.gform_wrapper input[type=text],
.gform_wrapper input[type=password],
.gform_wrapper input[type=tel],
.gform_wrapper input[type=email],
.gform_wrapper textarea {
  padding: 3px 10px !important;
  background: #efefef;
  border: 0px;
}

.gform_wrapper input[type=submit] {
  padding: 5px 21px 5px !important;
}

.gform_wrapper label {
  font-weight: normal !important;
}

#gform_1 {
  border: 1px solid #8c8c8c;
  padding: 30px !important;
  font-size: 0.9em;
}

#gform_1 label {
  display: none;
}

#gform_1 .gform_heading {
  text-align: center;
}

#gform_1 .gform_heading .gform_title {
  color: black;
  text-transform: none;
}

#gform_1 .gfield {
  margin-bottom: 15px !important;
}

#gform_1 .gform_footer {
  text-align: right;
}

/* 
  Woo Commerce forms
*/
abbr[title] {
  text-decoration: none;
}

input[type=text],
input[type=password],
input[type=tel],
input[type=email],
textarea {
  padding: 3px 10px !important;
  background: #efefef;
}

.woocommerce-invalid input[type=text], .woocommerce-invalid
input[type=password], .woocommerce-invalid
input[type=tel], .woocommerce-invalid
input[type=email], .woocommerce-invalid
textarea {
  border-color: #ba0000;
}

.woocommerce-invalid input[type=text],
.woocommerce-invalid input[type=password],
.woocommerce-invalid input[type=tel],
.woocommerce-invalid input[type=email],
.woocommerce-invalid textarea,
.woocommerce-validated input[type=text],
.woocommerce-validated input[type=password],
.woocommerce-validated input[type=tel],
.woocommerce-validated input[type=email],
.woocommerce-validated textarea {
  border-width: 1px;
}

.woocommerce-page form li:before {
  content: '' !important;
}

.main-sidebar ul {
  list-style: none;
  padding-left: 20px;
}

.main-sidebar ul li {
  margin-bottom: 8px;
}

.main-sidebar a {
  color: #ba0000;
}

.main-sidebar .children-toggle {
  display: inline-block;
  border-top: 1px solid black;
  border-right: 1px solid black;
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  margin-left: 3px;
  transition: all 250ms;
  cursor: pointer;
  position: relative;
}

.main-sidebar .children-toggle.opened {
  transform: rotate(134deg);
  top: -4px;
  left: 3px;
}

.main-sidebar .children {
  display: none;
}

.main-sidebar .current-cat {
  background-color: #efefef;
}

.main-sidebar .current-cat .children {
  background-color: white;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
}

.pagination .page-numbers {
  width: 32px;
  height: 32px;
  margin: 0 4px 4px 0;
  border: 1px solid black;
  color: #ba0000;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  transition: all 250ms;
  background: white;
  cursor: pointer;
  box-shadow: none;
  border-radius: 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  line-height: 1.8;
}

.pagination .page-numbers:hover {
  background: #ba0000;
  color: white;
}

.pagination .page-numbers:active {
  background: #870000;
}

.pagination .page-numbers.current {
  background: #ba0000;
  color: white;
}

.pagination .page-numbers.prev, .pagination .page-numbers.next, .pagination .page-numbers.min, .pagination .page-numbers.max {
  color: transparent;
  position: relative;
}

.pagination .page-numbers.prev:hover:after, .pagination .page-numbers.prev:hover:before, .pagination .page-numbers.next:hover:after, .pagination .page-numbers.next:hover:before, .pagination .page-numbers.min:hover:after, .pagination .page-numbers.min:hover:before, .pagination .page-numbers.max:hover:after, .pagination .page-numbers.max:hover:before {
  border-color: white;
}

.pagination .page-numbers.prev:before, .pagination .page-numbers.next:before, .pagination .page-numbers.min:before, .pagination .page-numbers.max:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #ba0000;
  border-left: 2px solid #ba0000;
  transform: rotate(-45deg);
  position: absolute;
  top: 10px;
  left: 13px;
}

.pagination .page-numbers.next:before {
  transform: rotate(135deg);
  left: 8px;
}

.pagination .page-numbers.min:before, .pagination .page-numbers.max:before {
  left: 16px;
}

.pagination .page-numbers.min:after, .pagination .page-numbers.max:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #ba0000;
  border-left: 2px solid #ba0000;
  transform: rotate(-45deg);
  position: absolute;
  top: 10px;
  left: 9px;
}

.pagination .page-numbers.max:before {
  transform: rotate(135deg);
  left: 12px;
}

.pagination .page-numbers.max:after {
  transform: rotate(135deg);
  left: 5px;
}

.woocommerce nav.woocommerce-pagination {
  text-align: left !important;
  margin-bottom: 30px;
  border: 0;
}

.woocommerce nav.woocommerce-pagination ul.page-numbers {
  border: 0;
}

.woocommerce nav.woocommerce-pagination ul.page-numbers li {
  border: 0;
}

.woocommerce nav.woocommerce-pagination ul.page-numbers a,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers {
  width: 32px;
  height: 32px;
  margin: 0 4px 4px 0;
  border: 1px solid black;
  color: #ba0000;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  transition: all 250ms;
  background: white;
  cursor: pointer;
  box-shadow: none;
  border-radius: 0;
  font-size: 18px;
  display: flex;
  justify-content: center;
  line-height: 1.8;
  padding: 0;
  min-width: auto;
}

.woocommerce nav.woocommerce-pagination ul.page-numbers a:hover,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers:hover {
  background: #ba0000;
  color: white;
}

.woocommerce nav.woocommerce-pagination ul.page-numbers a:active,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers:active {
  background: #870000;
}

.woocommerce nav.woocommerce-pagination ul.page-numbers a.current,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.current {
  background: #ba0000;
  color: white;
}

.woocommerce nav.woocommerce-pagination ul.page-numbers a.prev, .woocommerce nav.woocommerce-pagination ul.page-numbers a.next, .woocommerce nav.woocommerce-pagination ul.page-numbers a.min, .woocommerce nav.woocommerce-pagination ul.page-numbers a.max,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.prev,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.next,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.min,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.max {
  color: transparent;
  position: relative;
}

.woocommerce nav.woocommerce-pagination ul.page-numbers a.prev:hover:after, .woocommerce nav.woocommerce-pagination ul.page-numbers a.prev:hover:before, .woocommerce nav.woocommerce-pagination ul.page-numbers a.next:hover:after, .woocommerce nav.woocommerce-pagination ul.page-numbers a.next:hover:before, .woocommerce nav.woocommerce-pagination ul.page-numbers a.min:hover:after, .woocommerce nav.woocommerce-pagination ul.page-numbers a.min:hover:before, .woocommerce nav.woocommerce-pagination ul.page-numbers a.max:hover:after, .woocommerce nav.woocommerce-pagination ul.page-numbers a.max:hover:before,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.prev:hover:after,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.prev:hover:before,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.next:hover:after,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.next:hover:before,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.min:hover:after,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.min:hover:before,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.max:hover:after,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.max:hover:before {
  border-color: white;
}

.woocommerce nav.woocommerce-pagination ul.page-numbers a.prev:before, .woocommerce nav.woocommerce-pagination ul.page-numbers a.next:before, .woocommerce nav.woocommerce-pagination ul.page-numbers a.min:before, .woocommerce nav.woocommerce-pagination ul.page-numbers a.max:before,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.prev:before,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.next:before,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.min:before,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.max:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #ba0000;
  border-left: 2px solid #ba0000;
  transform: rotate(-45deg);
  position: absolute;
  top: 10px;
  left: 13px;
}

.woocommerce nav.woocommerce-pagination ul.page-numbers a.next:before,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.next:before {
  transform: rotate(135deg);
  left: 8px;
}

.woocommerce nav.woocommerce-pagination ul.page-numbers a.min:before, .woocommerce nav.woocommerce-pagination ul.page-numbers a.max:before,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.min:before,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.max:before {
  left: 16px;
}

.woocommerce nav.woocommerce-pagination ul.page-numbers a.min:after, .woocommerce nav.woocommerce-pagination ul.page-numbers a.max:after,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.min:after,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.max:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #ba0000;
  border-left: 2px solid #ba0000;
  transform: rotate(-45deg);
  position: absolute;
  top: 10px;
  left: 9px;
}

.woocommerce nav.woocommerce-pagination ul.page-numbers a.max:before,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.max:before {
  transform: rotate(135deg);
  left: 12px;
}

.woocommerce nav.woocommerce-pagination ul.page-numbers a.max:after,
.woocommerce nav.woocommerce-pagination ul.page-numbers span.page-numbers.max:after {
  transform: rotate(135deg);
  left: 5px;
}

.single-product-wrapper h2 {
  text-transform: uppercase;
}

.single-product-wrapper .woocommerce-breadcrumb {
  color: #8c8c8c;
  font-size: 0.8em;
}

.single-product-wrapper .woocommerce-breadcrumb a {
  color: #ba0000;
  box-shadow: none;
}

.single-product-wrapper .woocommerce-breadcrumb a:hover {
  color: #820000;
  text-decoration: underline;
}

.single-product-wrapper .wc-tabs a {
  box-shadow: none;
  text-decoration: none;
}

.single-product-wrapper .additional-links ul,
.single-product-wrapper .additional-links ol {
  list-style-position: inside;
}

.woocommerce-cart .shop_table th {
  text-transform: uppercase;
}

.woocommerce-cart .shop_table tr:nth-of-type(2n) {
  background: #efefef;
}

@media (max-width: 48em) {
  .woocommerce-cart .shop_table .product-remove {
    float: right;
  }
}

.woocommerce-cart .shop_table .product-thumbnail a {
  text-decoration: none;
  box-shadow: none;
}

.woocommerce-cart .wc-proceed-to-checkout {
  text-align: right;
}

.page-template-page-home .lead-in p {
  font-size: 1.1111111111em;
}

.page-template-page-home .block-section-gray.block-section-1-3 {
  font-size: 0.875em;
  line-height: 1.4;
}

@media (max-width: 61.25em) {
  .page-template-page-home .block-section-gray.block-section-1-3 .wrapper {
    display: block;
  }
}

.page-template-page-home .block-section-gray.block-section-1-3 .left {
  max-width: 266px;
  text-align: center;
}

.page-template-page-partners .partners-list .partner-section {
  position: relative;
  margin-bottom: 30px;
}

.page-template-page-partners .partners-list .partner-section:last-of-type {
  margin-bottom: 0;
}

.page-template-page-partners .partners-list .partner-section img {
  max-width: 200px;
  max-height: 100px;
  width: auto;
  height: auto;
  display: block;
  margin: 0 auto 10px;
}

.page-template-page-partners .partners-list .partner-section a {
  color: #ba0000;
  text-decoration: none;
  font-weight: 600;
}

.page-template-page-partners .partners-list .partner-section a:hover {
  text-decoration: underline;
}

.page-template-page-manufacturers .block-section-flex {
  background-attachment: fixed;
  background-image: url(../../images/backgrounds/product-bg.jpg);
}

.page-template-page-manufacturers hr {
  width: 100%;
}

.page-template-page-manufacturers .manufacturers-list {
  align-items: stretch;
}

.page-template-page-manufacturers .manufacturer-link {
  flex-wrap: wrap;
  position: relative;
  margin: 20px;
  width: 21%;
}

@media (max-width: 68.625em) {
  .page-template-page-manufacturers .manufacturer-link {
    width: 26%;
  }
}

@media (max-width: 48em) {
  .page-template-page-manufacturers .manufacturer-link {
    width: 44%;
  }
}

@media (max-width: 37.8125em) {
  .page-template-page-manufacturers .manufacturer-link {
    width: 91%;
  }
}

.page-template-page-manufacturers .manufacturer-link a {
  text-decoration: none;
  width: 100%;
  text-align: center;
}

.page-template-page-manufacturers .manufacturer-link a:hover img {
  transform: scale(0.9);
}

.page-template-page-manufacturers .manufacturer-link a:hover .title {
  color: white;
}

.page-template-page-manufacturers .manufacturer-link img {
  margin-bottom: 10px;
  padding: 5px;
  transition: all 250ms;
}

.page-template-page-manufacturers .manufacturer-link .title {
  width: 100%;
  background: black;
  color: #8c8c8c;
  transition: all 250ms;
  padding: 4px 10px 20px;
  text-align: left;
  font-size: 0.8em;
}

.page-template-page-manufacturers .manufacturer-link .button {
  position: absolute;
  right: 10px;
  transform: translateY(-50%);
}

.woocommerce .term-description * + * {
  margin-top: 20px;
}

@media screen and (max-width: 48em) {
  .js-menu-expanded {
    overflow-y: scroll;
  }
  .js-menu-expanded body {
    overflow: hidden;
    height: 100%;
  }
}

@media screen and (max-width: 48em) {
  .site-menu {
    height: 0vh;
    transition: height 0.35s ease-out;
  }
  .js-menu-expanded .site-menu {
    width: calc(100% + 50px);
    height: calc(100vh - 98px);
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 50px;
  }
}

@media screen and (max-width: 48em) {
  .main-header {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    transition: background-color 0.35s linear;
  }
  .js-menu-expanded .main-header {
    background-color: white;
  }
  .js-menu-expanded .main-header .wrapper {
    overflow: hidden;
  }
  .main-header ~ main {
    padding-top: 98px;
  }
}

@media (max-width: 25em) {
  .main-header ~ main {
    padding-top: 70px;
  }
}

@media screen and (max-width: 48em) {
  .main-menu {
    opacity: 0;
    padding-top: 10px;
    padding-bottom: 44px;
    overflow: hidden;
    transition: opacity 0.35s linear;
  }
  .js-menu-expanded .main-menu {
    opacity: 1;
  }
}

@media screen and (max-width: 48em) {
  .main-menu .menu-item {
    opacity: 0;
    transform: scale(1.1) translateY(-100%);
    transition: opacity 0.35s ease-out, transform 0.35s ease-out;
  }
  .main-menu .menu-item:nth-child(1) {
    transition-delay: 300ms, 300ms;
  }
  .main-menu .menu-item:nth-child(2) {
    transition-delay: 350ms, 350ms;
  }
  .main-menu .menu-item:nth-child(3) {
    transition-delay: 400ms, 400ms;
  }
  .main-menu .menu-item:nth-child(4) {
    transition-delay: 450ms, 450ms;
  }
  .main-menu .menu-item:nth-child(5) {
    transition-delay: 500ms, 500ms;
  }
  .main-menu .menu-item:nth-child(6) {
    transition-delay: 550ms, 550ms;
  }
  .main-menu .menu-item:nth-child(7) {
    transition-delay: 600ms, 600ms;
  }
  .main-menu .menu-item:nth-child(8) {
    transition-delay: 650ms, 650ms;
  }
  .main-menu .menu-item:nth-child(9) {
    transition-delay: 700ms, 700ms;
  }
  .main-menu .menu-item:nth-child(10) {
    transition-delay: 750ms, 750ms;
  }
  .main-menu .menu-item:nth-child(11) {
    transition-delay: 800ms, 800ms;
  }
  .main-menu .menu-item:nth-child(12) {
    transition-delay: 850ms, 850ms;
  }
  .js-menu-expanded .main-menu .menu-item {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.main-menu a {
  display: block;
  vertical-align: middle;
  line-height: 0.8;
  text-decoration: none;
  text-align: left;
  padding: 16px;
}

@media screen and (max-width: 48em) {
  .main-menu a {
    border-bottom: solid 1px rgba(0, 0, 0, 0.25);
  }
}

.main-menu-container {
  background: black;
}

.main-menu {
  list-style: none;
}

@media screen and (min-width: 48.0625em) {
  .main-menu {
    font-size: 0.1px;
    text-align: justify;
  }
  .main-menu:after {
    content: '';
    display: inline-block;
    width: 100%;
  }
  .main-menu > * {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    font-size: 1rem;
  }
}

.main-menu .menu-item-has-children a {
  display: inline-block;
}

.main-menu .menu-item-has-children:before {
  display: none;
  position: relative;
  content: '';
  width: 10px;
  height: 10px;
  top: -2px;
  left: 14px;
  border-top: 1px solid white;
  border-right: 1px solid white;
  transform: rotate(45deg);
  transition: all 250ms;
}

.main-menu .menu-item-has-children:hover:before {
  top: -4px;
  transform: rotate(135deg);
}

@media (max-width: 48em) {
  .main-menu .menu-item-has-children a {
    padding-left: 26px !important;
  }
  .main-menu .menu-item-has-children:before {
    display: inline-block;
  }
}

.main-menu .menu-item-with-svg {
  position: relative;
}

.main-menu .menu-item-with-svg a {
  padding-left: 22px !important;
}

.main-menu .menu-item-with-svg svg {
  position: absolute;
  height: 20px;
  width: 20px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.main-menu .menu-item {
  color: black;
  text-transform: uppercase;
}

.main-menu .menu-item:hover a {
  color: #ba0000;
}

.main-menu .menu-item:hover .sub-menu {
  display: block;
}

.main-menu .menu-item a {
  color: white;
  transition: color 250ms;
  font-weight: 600;
  padding: 10px 16px;
}

@media (max-width: 1110px) {
  .main-menu .menu-item a {
    font-size: 0.875em;
    padding: 10px;
  }
}

@media (max-width: 56.5em) {
  .main-menu .menu-item a {
    font-size: 0.75em;
    padding: 10px 8px;
  }
}

@media (max-width: 48em) {
  .main-menu .menu-item a {
    font-size: 1em;
    padding: 16px;
  }
}

.main-menu .sub-menu {
  list-style: none;
  display: none;
  background: black;
}

@media screen and (max-width: 48em) {
  .main-menu .sub-menu {
    display: none;
    background: #8c8c8c;
  }
}

@media screen and (min-width: 48.0625em) {
  .main-menu .sub-menu {
    position: absolute;
    z-index: 1;
    white-space: nowrap;
  }
}

.main-menu .sub-menu a {
  color: white !important;
  display: block !important;
}

.main-menu .sub-menu a:hover {
  color: #ba0000 !important;
}

@media screen and (max-width: 48em) {
  .mobile-menu-button {
    display: inline-block;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
  }
}

@media screen and (max-width: 48em) {
  .mobile-button-icon {
    display: block;
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    width: 18px;
    height: 1px;
    background-color: black;
    transition: background-color 0ms linear 200ms;
  }
  .mobile-button-icon:before, .mobile-button-icon:after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: black;
    transform: rotate(0deg);
    transition: top 200ms ease-in-out 150ms, transform 150ms ease-in-out;
  }
  .mobile-button-icon:before {
    top: -5px;
  }
  .mobile-button-icon:after {
    top: calc(100% + 4px);
  }
}

.js-menu-expanded .mobile-button-icon {
  background-color: transparent;
  transition: background-color 0ms linear 200ms;
}

.js-menu-expanded .mobile-button-icon:before, .js-menu-expanded .mobile-button-icon:after {
  top: 0;
  transition: top 200ms ease-in-out, transform 150ms ease-in-out 150ms;
}

.js-menu-expanded .mobile-button-icon:before {
  transform: rotate(45deg);
}

.js-menu-expanded .mobile-button-icon:after {
  transform: rotate(-45deg);
}

@keyframes slidein-animation {
  0% {
    opacity: 0;
  }
  1% {
    transform: translateX(160px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.horizontal-scroll-menu {
  overflow: hidden;
  height: 44px;
  padding: 4px 0;
}

.horizontal-scroll-menu .horizontal-scroll-menu-items {
  width: 100%;
  font-size: 0.1px;
  white-space: nowrap;
  padding-bottom: 50px;
  overflow-x: auto;
  overflow-y: hidden;
  appearance: none;
  -webkit-overflow-scrolling: touch;
}

.horizontal-scroll-menu .horizontal-scroll-menu-items > .horizontal-scroll-menu-item {
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
  padding: 0 5px;
  animation-name: slidein-animation;
  animation-duration: 350ms;
  animation-fill-mode: backwards;
}

.horizontal-scroll-menu .horizontal-scroll-menu-link {
  display: block;
  color: inherit;
  text-decoration: none;
  padding: 10px 5px;
}

.content-editor blockquote {
  border-left: 4px solid black;
  color: #404040;
  font-size: 1.1875em;
  font-style: italic;
  margin-bottom: 26px;
  padding: 0 0 6px 20px;
}

.content-editor blockquote.alignleft, .content-editor blockquote.alignright {
  border: 0 solid black;
  border-top-width: 4px;
  padding: 14px 0 0;
  width: calc(50% - 14px);
}

.content-editor address {
  font-style: italic;
}

.content-editor pre {
  border: solid 1px #bfbfbf;
  line-height: 1.3125;
  max-width: 100%;
  overflow: auto;
  padding: 14px;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.content-editor sup,
.content-editor sub {
  font-size: 75%;
  height: 0;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.content-editor sub {
  top: -6px;
}

.content-editor sup {
  bottom: -3px;
}

.content-editor small {
  font-size: 80%;
}

.content-editor big {
  font-size: 125%;
}

.content-editor hr {
  background-color: #bfbfbf;
  border: 0;
  height: 1px;
}

.content-editor .alignleft {
  float: left;
  margin: 6px 26px 26px 0;
}

.content-editor .alignright {
  float: right;
  margin: 6px 0 26px 26px;
}

.content-editor .aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 26px;
}

.wp-caption {
  background: transparent;
  border: none;
  margin-bottom: 26px;
  max-width: 100%;
  padding: 0;
  text-align: inherit;
}

.wp-caption-text,
.wp-caption-dd {
  color: #404040;
  font-size: 0.8125em;
  font-style: italic;
  padding-top: 7px;
}

.content-editor > * + * {
  margin-top: 1.625em;
}

.content-editor h1,
.content-editor h2,
.content-editor h3,
.content-editor h4,
.content-editor h5,
.content-editor h6 {
  line-height: 1.1;
}

.content-editor h1 + *,
.content-editor h2 + *,
.content-editor h3 + *,
.content-editor h4 + *,
.content-editor h5 + *,
.content-editor h6 + * {
  margin-top: 0.8125em;
}

.content-editor a:not(.button) {
  box-shadow: 0 0 0 0 #ba0000;
  color: #ba0000;
  text-decoration: none;
  transition: color 0.1s ease, box-shadow 0.1s ease;
}

.content-editor a:not(.button):hover, .content-editor a:not(.button):focus {
  color: #820000;
  box-shadow: 0 1px 0 0 #820000;
}

.content-editor ul,
.content-editor ol {
  list-style: none;
  list-style-position: inside;
  margin-top: 0;
}

.content-editor ul li,
.content-editor ol li {
  position: relative;
}

.content-editor ul li {
  padding-left: 1.25em;
}

.content-editor ul li:before {
  content: '\2022';
  position: absolute;
  left: 0.4375em;
}

.content-editor ol {
  counter-reset: num-list;
}

.content-editor ol li {
  padding-left: 2em;
}

.content-editor ol li:before {
  counter-increment: num-list;
  content: counter(num-list) ".";
  position: absolute;
  left: 0;
  width: 1.625em;
  text-align: right;
}

.color-black {
  color: black;
}

.color-gray {
  color: #8c8c8c;
}

.color-red {
  color: #ba0000;
}

.color-white {
  color: white;
}

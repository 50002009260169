.page-template-page-manufacturers {
	.block-section-flex {
		@extend %background-cover;
		background-attachment: fixed;
		background-image:url(../../images/backgrounds/product-bg.jpg);		
	}

	hr {
		width: 100%;
	}

	.manufacturers-list {
		align-items: stretch;
	}

	.manufacturer-link {
		flex-wrap: wrap;
		// padding: 10px;
		// border: 1px solid $gray;
		position: relative;
		margin: 20px;
		width: 21%;

		@media (max-width:em(1098px)){
			width: 26%;
		}

		@media (max-width:em($mobile-menu-switch-max)){
			width: 44%;
		}

		@media (max-width:em(605px)){
			width: 91%;
		}


		a {
			text-decoration: none;
			width: 100%;
			text-align: center;

			&:hover {
				img {
					transform: scale(0.9);
				}

				.title {
					color: white;
				}
			}
		}

		img {
			margin-bottom: 10px;
			padding: 5px;
			transition: all 250ms;
		}

		.title {
			width: 100%;
			background: $black;
			color: $gray;
			transition: all 250ms;
			padding: 4px 10px 20px;
			text-align: left;
			font-size: 0.8em;
		}

		.button {
			position: absolute;
			right: 10px;
			transform: translateY(-50%);
		}
	}
}
*,
*:before,
*:after {
  margin:0;
  padding:0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none !important;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

.xdebug-var-dump {
  background: white !important;
  color: black !important;
}

html,
body {
  height:100%;
  position: relative;
}

body {
  font: {
    family: $font-main;
    size: 18px;
    weight: normal;
  }
  line-height: (26/16); // Golden Ratio scale
  color: $font-base-color;
  background-color: $body-background-color;

  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  justify-content: space-between;

  .footer-push {
    width: 100%;
    align-self: flex-start;
  }
  
  footer {
    width: 100%;
    align-self: flex-end;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: normal;
}

h1 {
  font-weight: 600;
  font-size: em(28px, 18px);
  text-transform: uppercase;
  letter-spacing: 1px;
}

h2 {
  font-weight: 600;
  // font-size: em(28px, 18px);
  font-size: 28px;
}

h3 {
  font-weight: 600;
  color: $red;
  text-transform: uppercase;
  font-size: em(18px, 18px);
}

h4 {
  // font-size: em(22px, 18px);
  font-size: 22px;
  font-weight: 600;
}

img {
  max-width: 100%;
  height: auto;
}

ul,
ol {
  list-style-position: inside;
}

.loading {
  width: 22px;
  height: 22px;
  background-image:url(../../images/icons/loading.gif);
  display: inline-block;
}

.wrapper {
  position: relative;
  max-width: #{$wrapperWidth}px;
  margin: 0 auto;
  width: 91%;
}

.block-section {
  padding: 40px 0;

  &-background-image {
    @extend %background-cover;
    background-attachment: fixed;
  }

  //VERTICAL
  &-valign-center {
    .wrapper { align-items: center; }
  }

  //COLORS
  &-gray {
    background: $gray;
    color: white;
  }

  //COLUMNS
  &-1-3 {
    .wrapper {
      display: flex;
      justify-content: space-between;

      .left {
        flex: 1;
        margin: 0 40px 20px 0;
      }

      .right {
        flex: 3;
      }
    }
  }

  &-3-1 {
    .wrapper {
      display: flex;
      justify-content: space-between;

      @media (max-width:em($mobile-menu-switch-max)){
        display: block;
      }

      .left {
        flex: 3;
        margin: 0 40px 20px 0;
  
        @media (max-width:em($mobile-menu-switch-max)){
          margin: 0 0 20px 0;
        }        
      }

      .right {
        flex: 1;
      }
    }
  }

  &-flex {
    .wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
}

.columns {
  display: flex;
  justify-content: space-between;

  & > *:not(.column) {
    display: none !important;
  }

  @media(max-width:em($mobile-menu-switch-max)){
    display: block;
  }

  .column {
    width: 48%;
    margin-top: 0 !important;
    
    @media(max-width:em($mobile-menu-switch-max)){
      width: 100%;
    }    

    &:first-of-type {
      @media(max-width:em($mobile-menu-switch-max)){
        margin-bottom: 40px;
      }
    }
  }
}

.blog .post,
.archive .post {
  display: flex;
  align-items: flex-start;

  @media(max-width:em($mobile-menu-switch-max)){
    display: block;
  }

  .wp-post-image {
    max-width: 175px;
    display: block;
    margin: 0 auto;

    @media(max-width:em($mobile-menu-switch-max)){
      max-width: 100%;
    }

    & + .post-content {
      margin-left: 20px;
    }
  }
}
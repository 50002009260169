.main-footer {
  height: auto;
  background-color: $main-footer-background-color;
  box-shadow: -1px 0px 50px -20px;

  a:not(.button) {
  	color: white !important;
  }
}

.footer-top {
	padding: 40px 0;
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: contain;	

	@media(max-width:em($mobile-menu-switch-max)){
		background-image:url() !important;
	}

  .wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;

		@media(max-width:em($mobile-menu-switch-max)){
			display: block;
		}
  }

  .column {
	  // background: pink;
	  flex: 1;
	  // height: 160px;

	  &:nth-of-type(2){
	  	flex: 2;
	  	// background: hotpink;
	  }

	  &:nth-of-type(3){
	  	@media(max-width: em(900px)){
	  		flex: 0;
	  	}
	  }
  }
}

.footer-bottom {
	background-color: $main-footer-bottom-background-color;
	color: white;
	font-size: em(10px);

	.wrapper {
		padding: 15px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media(max-width:em($mobile-menu-switch-max)){
			display: block;
			text-align: center;

			.content-editor {
				margin-bottom: 5px;
			}
		}

		.social {
			white-space: nowrap;
		}
	}

	img {
		margin-right: 8px;
	}
}
.main-menu-container {
  background: $main-header-navigation-background-color;
}

.main-menu {
  list-style: none;

  @media screen and (min-width: em($mobile-menu-switch-min)) {
    @include fluid-grid();
  }

  .menu-item-has-children {
    a {
      display: inline-block;
    }

    &:before {
      display: none;
      position: relative;
      content: '';
      width: 10px;
      height: 10px;
      top: -2px;
      left: 14px;
      border-top: 1px solid white;
      border-right: 1px solid white;
      transform: rotate(45deg);
      transition: all 250ms;
    }

    &:hover:before {
      top: -4px;
      transform: rotate(135deg);
    }

    @media (max-width:em($mobile-menu-switch-max)){
      a {
        padding-left: 26px !important;
      }
      &:before {
        display: inline-block;
      }
    }
  }

  .menu-item-with-svg {
    position: relative;

    a {
      padding-left: 22px !important;
    }

    svg {
      position: absolute;
      height: 20px;
      width: 20px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .menu-item {
    color: $main-menu-item-color;
    text-transform: uppercase;
  
    &:hover {
      a {
        color: $red;
      }

      .sub-menu {
        display: block;
      }
    }

    a {
      color: white;
      transition: color 250ms;
      font-weight: 600;
      padding: 10px 16px;

      @media (max-width:1110px){
        font-size: em(14px);
        padding: 10px;
      }

      @media (max-width:em(904px)){
        font-size: em(12px);
        padding: 10px 8px;
      }      

      @media (max-width:em($mobile-menu-switch-max)){
        font-size: 1em;
        padding: 16px;
      }      
    }
  }

  .sub-menu {
    list-style: none;
    display: none;
    background: $black;

    @media screen and (max-width: em($mobile-menu-switch-max)) {
      display: none;
      background: $gray;
    }
    @media screen and (min-width: em($mobile-menu-switch-min)) {
      position: absolute;
      z-index: 1;
      white-space: nowrap;
    }

    a {
      color: white !important;
      display: block !important;
      
      &:hover {
        color: $red !important;
      }
    }
  }
}


.mobile-menu-button {
  @extend %hidden;

  @media screen and (max-width: em($mobile-menu-switch-max)) {
    display: inline-block;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
  }
}
.mobile-button-icon {

  @media screen and (max-width: em($mobile-menu-switch-max)) {
    display: block;
    position: absolute;
    top: #{(48/2)}px;
    left: 50%;
    transform: translateX(-50%);
    width: 18px;
    height: 1px;
    background-color: $mobile-menu-button-color;
    transition: background-color 0ms linear 200ms;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $mobile-menu-button-color;
      transform: rotate(0deg);
      transition: top 200ms ease-in-out 150ms, transform 150ms ease-in-out;
    }
    &:before {
      top: -5px;
    }
    &:after {
      top: calc(100% + 4px);
    }
  }

  .js-menu-expanded & {
    background-color: transparent;
    transition: background-color 0ms linear 200ms;

    &:before,
    &:after {
      top: 0;
      transition: top 200ms ease-in-out, transform 150ms ease-in-out 150ms;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

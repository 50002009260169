.hero {
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
 	background-attachment: fixed;
	height: 455px;
	position: relative;
	color: white;

	@include color-overlay(rgba(mix($red, black, 15%), 0.3));

	@media(max-width:em($mobile-menu-switch-max)){
		height: 350px;
		background-attachment: scroll;
		background-size: cover;
		background-position: center;
	}

	&.inner-hero {
		height: 225px;
  	background-position: center;
  	background-attachment: scroll;
	}

	&.no-image {
		height: auto;
		padding: 60px 0 0;
		color: $black;

		.wrapper {
			position: static;
			transform: none;
		}

		&:after {
			display: none;
		}
	}

	.wrapper {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;

		& > * {
			@media (max-width:em(1000px)){
				text-align: center !important;
			}
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		//text-shadow: 2px 2px 0px black;
	}

	h1 {
		font-weight: 600;
		font-size: em(40px, 18px);
		letter-spacing: 2px;
		line-height: 1em;

		@media(max-width:em($mobile-menu-switch-max)){
			font-size: em(32px);
		}
	}

	h2 {
		font-weight: normal;
		font-size: em(34px, 18px);
		line-height: 1.3em;
		letter-spacing: 2px;

		@media(max-width:em($mobile-menu-switch-max)){
			font-size: em(28px);
		}
	}

	a {
		color: $link-color;
		text-decoration: none;
		transition: all 250ms;

		&:hover {
			color: $link-interaction-color;
			text-decoration: underline;
		}

	}
}
button {
  -webkit-appearance:none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
}

.woocommerce .button,
.woocommerce .button.alt,
.woocommerce div.product form.cart .button,
.woocommerce #respond input#submit.alt, 
.woocommerce a.button.alt, 
.woocommerce button.button.alt, 
.woocommerce input.button.alt,
.woocommerce #respond input#submit, 
.woocommerce a.button, 
.woocommerce button.button, 
.woocommerce input.button,

.woocommerce #respond input#submit.disabled, 
.woocommerce #respond input#submit:disabled, 
.woocommerce #respond input#submit:disabled[disabled], 
.woocommerce a.button.disabled, .woocommerce a.button:disabled, 
.woocommerce a.button:disabled[disabled], 
.woocommerce button.button.disabled, 
.woocommerce button.button:disabled, 
.woocommerce button.button:disabled[disabled], 
.woocommerce input.button.disabled, 
.woocommerce input.button:disabled, 
.woocommerce input.button:disabled[disabled],

.button {
	border: 1px solid $black;
	color: $button-color;
	display: inline-block;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 600;
	transition: all 250ms;
	background: $button-background;
	cursor: pointer;
	box-shadow: none;
	line-height: 1;
	border-radius: 0;
	font-size: 18px;
	padding: 8px 21px 5px;

	&:hover {
		background: $button-background-hover;
		color: $button-color-hover;
	}

	&:active {
		background: darken($button-background-hover, 10%);
	}
}
.button-large {
	//default
}
.button-small {
	font-size: 14px !important;
	padding: 4px 11px !important;
}


.woocommerce #respond input#submit.disabled, 
.woocommerce #respond input#submit:disabled, 
.woocommerce #respond input#submit:disabled[disabled], 
.woocommerce a.button.disabled, .woocommerce a.button:disabled, 
.woocommerce a.button:disabled[disabled], 
.woocommerce button.button.disabled, 
.woocommerce button.button:disabled, 
.woocommerce button.button:disabled[disabled], 
.woocommerce input.button.disabled, 
.woocommerce input.button:disabled, 
.woocommerce input.button:disabled[disabled] {
	&:hover {
		cursor: not-allowed;
		background: white;
		color: $red;
	}
}
.touch-mega-menu {
	display: none !important;

	&.active {
		display: block !important;
	}
}

.mega-menu {
	left: 0;
	width: 100%;
	color: white;
	z-index: 99 !important;
	padding: 10px;


	.manufacturers-list,
	.product-categories-list {
		background: white;
		text-align: center;

		img {
			max-height: 125px;
		}

		a {
			border: 0;
			line-height: 1.4;
			white-space: normal;
			text-align: center;
		}
	}
}
// imported theme fonts
// @font-face font imports from sass/fonts
// ---------------------------------

// @import "../fonts/font-font-name";
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700');


// Theme brand colors
// ---------------------------------

$theme-black : #333333;
$theme-blue  : #0088cc;

$red 	 			: #ba0000;
$black 			: black;
$gray  			: #8c8c8c;
$light-gray : #efefef;
$green 			: #77a464;

// Theme specific variables
// ---------------------------------

$body-background-color : white;

$wrapperWidth  : 1170; // px value


$font-main   : 'Montserrat', Helvetica, Arial, sans-serif;
$font-accent : 'accent font name', 'fallback fonts';


$font-base-color : $black;
$link-color      : $red;

$mobile-header-height: 100px;


// $footer-background-color : transparent;

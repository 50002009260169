.related.products {
	h2 {
		width: 100%;
		margin-bottom: 20px;
	}

	.products {
		width: 100%;
	}
}

.product-categories-list {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: baseline;

	.product-link {
		width: 15%;
		text-align: center;
		margin: 10px;
	  display: flex;
	  justify-content: center;
	  align-items: flex-end;
	  text-transform: uppercase;

	  @media(max-width:em(1224px)){
	  	width: 20%;
	  }

	 	@media(max-width:em(946px)){
	  	width: 30%;
	  }

	 	@media(max-width:em($mobile-menu-switch-max)){
	  	width: 40%;
	  }

	 	@media(max-width:em(480px)){
	  	width: 100%;
	  }

		a {
			color: $red;
			text-decoration: none;
			font-weight: 600;
		}

		&:hover {
			text-decoration: underline;
		}

		img {
			display: block;
			margin: 0 auto 10px;
			max-height: 175px;
		}
	}
}

.product-list-full-details,
.products  {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: baseline;

	&:before,
	&:after {
		display: none !important;
	}

	.product-full-details,
	.product {
		position: relative;
		width: 21% !important;
		margin: 0 0 40px 0 !important;

		@media (max-width:em(1098px)){
			width: 26% !important;
		}

		@media (max-width:em($mobile-menu-switch-max)){
			width: 44% !important;
		}

		@media (max-width:em(605px)){
			width: 91% !important;
		}

		&:hover {
			img { transform: scale(0.9); }
			a:not(.button) { color: white; }
		}

		img {
			max-width: 150px;
			max-height: 150px;
			width: auto;
			height: auto;
			transition: all 250ms;
			margin: 0 auto 10px !important;
			display: block;
			padding: 5px;
		}

		.product-content {
			background: $black;
			padding: 4px 10px 20px;
			text-align: left;

			.category {
				font-size: 0.857em;
			}

			.price {
				color: $green;
				font-size: 0.857em;
			}
		}

		a:not(.button) {
			color: $gray;
			text-decoration: none;
			transition: all 250ms;			
		}

		.button {
			position: absolute;
			right: 10px;
			transform: translateY(-50%);
			margin: 0 !important;

			svg {
				width: 20px;
				height: 20px;
			}
		}
	}
}
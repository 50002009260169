.single-product-wrapper a,
.woocommerce-cart-form a:not(.remove),
.link {
  box-shadow: 0 0 0 0 $link-color;
  color: $link-color;
  text-decoration: none;
  transition: color 0.1s ease, box-shadow 0.1s ease;

  &:hover,
  &:focus {
    color: $link-interaction-color;
    box-shadow: 0 1px 0 0 $link-interaction-color;
  }
}

.woocommerce-breadcrumb {
	color: $link-color !important;
  font-size: 0.8em !important;

	a {
		color: $link-color !important;
    text-decoration: none;

		&:hover {
			color: $link-interaction-color;
      text-decoration: underline;
		}
	}
}
.page-template-page-partners {
	.partners-list {
		.partner-section {
			position: relative;
			margin-bottom: 30px;

			&:last-of-type {
				margin-bottom: 0;
			}

			img {
				max-width: 200px;
				max-height: 100px;
				width: auto;
				height: auto;
				display: block; 
				margin: 0 auto 10px;
			}

			a {
				color: $red;
				text-decoration: none;
				font-weight: 600;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}
.page-headline {
  @include fluid-text($font-min: 24px, $viewport-min: 320px, $font-max: 42px, $viewport-max: 1170px);
}

.title-hr {
	text-align: center;
	position: relative;
  font-weight: 600;
  font-size: em(28px, 18px);
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  width: 100%;
  text-transform: uppercase;

  .word {
  	margin: 0 10px;
  }

	.line {
		height: 2px;
		background: $red;
		flex: 1;
	}
}
.page-template-page-home {
	.lead-in {
		p {
			font-size: em(20px, 18px);
		}
	}

	.block-section-gray.block-section-1-3 {
		font-size: em(14px);
		line-height: 1.4;

		.wrapper {
			@media (max-width:em(980px)){
				display: block;
			}
		}

		.left {
			max-width: 266px;
			text-align: center;
		}
	}
}
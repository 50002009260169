.single-product-wrapper {

	h2 {
		text-transform: uppercase;
	}


	.woocommerce-breadcrumb {
		color: $gray;
		font-size: 0.8em;

		a {
			color: $link-color;
			box-shadow: none;

			&:hover {
				color: $link-interaction-color;
				text-decoration: underline;
			}
		}
	}

	.wc-tabs {
		a {
			box-shadow: none;
			text-decoration: none;
		}
	}

	.additional-links {
		ul,
		ol {
			list-style-position: inside;
		}
	}
}
/*
  General Inputs
*/

input[type=text],
input[type=password],
input[type=tel],
input[type=email],
textarea {
	padding: 3px;
	border-style: solid;
	border-width: 0;
}

input[type=checkbox] {
	appearance: none;
	border: 1px solid black;
	display: inline-block !important;
	width: 18px;
	height: 18px;
	cursor: pointer;

	&:checked {
		background-image:url(../../images/icons/x.svg);
		@extend %background-cover;
	}
}



/*
  Gravity Forms
*/
.gform_wrapper {
	* {
		margin: 0 !important;
		padding: 0 !important;
	}

	.gform_heading {
		margin-bottom: 10px !important;
	}

	.gform_fields {
		.gfield {
			margin-bottom: 10px !important;
		}
	}

	.ginput_complex {
		display: flex;
		justify-content: space-between;
		width: 100% !important;

		@media (max-width:em(515px)){
			display: block;
		}

		& > * {
			width: 48% !important;
			@media (max-width:em(515px)){
				width: 100% !important;
			}
		}
	}

	.gform_drop_area {
		padding: 24px !important;
	}

	input[type=text],
	input[type=password],
	input[type=tel],
	input[type=email],
	textarea {
		padding: 3px 10px !important;
		background: $light-gray;
		border: 0px;
	}

	input[type=submit]{
		padding: 5px 21px 5px !important;
	}

	label {
		font-weight: normal !important;
	}
}

// STAY IN TOUCH IN THE FOOTER
#gform_1 {
	border: 1px solid $gray;
	padding: 30px !important;
	font-size: 0.9em;

	label {
		display: none;
	}

	.gform_heading {
		text-align: center;

		.gform_title {
			color: black;
			text-transform: none;
		}
		.gform_description {
		}
	}

	.gfield {
		margin-bottom: 15px !important;
	}

	.gform_footer {
		text-align: right;
	}
}

/* 
  Woo Commerce forms
*/
abbr[title] {
	text-decoration: none;
}

input[type=text],
input[type=password],
input[type=tel],
input[type=email],
textarea {
	padding: 3px 10px !important;	
	background: $light-gray;

	.woocommerce-invalid & {
		border-color: $red;
	}
}

.woocommerce-invalid,
.woocommerce-validated {
	input[type=text],
	input[type=password],
	input[type=tel],
	input[type=email],
	textarea {
		border-width: 1px;
	}
}

.woocommerce-page form {
	li:before {
		content: '' !important;
	}
}
.manufacturers-list {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: baseline;

	.manufacturer-link {
		width: 15%;
		margin: 10px;
	  display: flex;
	  justify-content: center;
	  align-items: flex-end;

	  @media(max-width:em(1224px)){
	  	width: 20%;
	  }

	 	@media(max-width:em(946px)){
	  	width: 30%;
	  }

	 	@media(max-width:em($mobile-menu-switch-max)){
	  	width: 40%;
	  }

	 	@media(max-width:em(480px)){
	  	width: 100%;
	  }
	}
}
.woocommerce-cart {
	.shop_table {
		// text-align: center !important;

		th {
			text-transform: uppercase;
		}

		tr:nth-of-type(2n){
			background: #efefef;
		}

		.product-remove {
			@media(max-width:em($mobile-menu-switch-max)){
				float: right;
			}
		}

		// .product-quantity, 
		// .quantity {
		// 	text-align: right;
		// }

		.product-thumbnail a {
			text-decoration: none;
			box-shadow: none;
		}
	}

	.wc-proceed-to-checkout {
		text-align: right;
	}
}
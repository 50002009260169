.pagination {
	display: flex;
	flex-wrap: wrap;

	.page-numbers {
		width: 32px;
		height: 32px;
		margin: 0 4px 4px 0;
		border: 1px solid $black;
		color: $button-color;
		display: inline-block;
		text-transform: uppercase;
		text-decoration: none;
		font-weight: 600;
		transition: all 250ms;
		background: $button-background;
		cursor: pointer;
		box-shadow: none;
		border-radius: 0;
		font-size: 18px;
		display: flex;
		justify-content: center;
		line-height: 1.8;

		&:hover {
			background: $button-background-hover;
			color: $button-color-hover;
		}

		&:active {
			background: darken($button-background-hover, 10%);
		}

		&.current {
			background: $button-background-hover;
			color: $button-color-hover;
		}

		&.prev,
		&.next,
		&.min,
		&.max {
			color: transparent;
			position: relative;
			
			&:hover:after,
			&:hover:before {
				border-color: $button-color-hover;
			}

			&:before {
				content: '';
				display: block;
				width: 10px;
				height: 10px;
			  border-top: 2px solid $button-color;
			  border-left: 2px solid $button-color;
			  transform: rotate(-45deg);
			  position: absolute;
			  top: 10px;
			  left: 13px;
	  	}
		}

		&.next:before {
			transform: rotate(135deg);
			left: 8px;
		}

		&.min,
		&.max {
			&:before {
				left: 16px;
			}

			&:after {
				content: '';
				display: block;
				width: 10px;
				height: 10px;
			  border-top: 2px solid $button-color;
			  border-left: 2px solid $button-color;
			  transform: rotate(-45deg);
			  position: absolute;
			  top: 10px;
			  left: 9px;
			}
		}

		&.max {
			&:before {
			  transform: rotate(135deg);
			  left: 12px;
			}

			&:after {
			  transform: rotate(135deg);
			  left: 5px;
			}
		}
	}
}

.woocommerce nav.woocommerce-pagination {
	text-align: left !important;
	margin-bottom: 30px;
	border: 0;

	ul.page-numbers {
		border: 0;

		li {
			border: 0;
		}

		a,
		span.page-numbers {
			width: 32px;
			height: 32px;
			margin: 0 4px 4px 0;
			border: 1px solid $black;
			color: $button-color;
			display: inline-block;
			text-transform: uppercase;
			text-decoration: none;
			font-weight: 600;
			transition: all 250ms;
			background: $button-background;
			cursor: pointer;
			box-shadow: none;
			border-radius: 0;
			font-size: 18px;
			display: flex;
			justify-content: center;
			line-height: 1.8;
			padding: 0;
			min-width: auto;

			&:hover {
				background: $button-background-hover;
				color: $button-color-hover;
			}

			&:active {
				background: darken($button-background-hover, 10%);
			}

			&.current {
				background: $button-background-hover;
				color: $button-color-hover;
			}

			&.prev,
			&.next,
			&.min,
			&.max {
				color: transparent;
				position: relative;
				
				&:hover:after,
				&:hover:before {
					border-color: $button-color-hover;
				}

				&:before {
					content: '';
					display: block;
					width: 10px;
					height: 10px;
				  border-top: 2px solid $button-color;
				  border-left: 2px solid $button-color;
				  transform: rotate(-45deg);
				  position: absolute;
				  top: 10px;
				  left: 13px;
		  	}
			}

			&.next:before {
				transform: rotate(135deg);
				left: 8px;
			}

			&.min,
			&.max {
				&:before {
					left: 16px;
				}

				&:after {
					content: '';
					display: block;
					width: 10px;
					height: 10px;
				  border-top: 2px solid $button-color;
				  border-left: 2px solid $button-color;
				  transform: rotate(-45deg);
				  position: absolute;
				  top: 10px;
				  left: 9px;
				}
			}

			&.max {
				&:before {
				  transform: rotate(135deg);
				  left: 12px;
				}

				&:after {
				  transform: rotate(135deg);
				  left: 5px;
				}
			}
		}
	}
}
.main-header {
  background-color: $main-header-background-color;

  @media (max-width:em($mobile-menu-switch-max)){
  	border-bottom: 1px solid $black;
  }

  @media (max-width:em(400px)){
    min-height: 70px;
  }
}

.header-top-wrapper {
	display: flex;
	padding: 10px 0;
	color: $red;

	a {
		color: $red;
		text-decoration: none;
	}

  .site-logo {
  	@media (max-width:em($mobile-menu-switch-max)){
  		margin-left: 30px;
  	}

  	img {
	  	height: 51px;
	  	width: 327px;

	  	@media (max-width:em(400px)){
	  		max-width: 100%;
	  		height: auto;
	  	}
  	}

  	a {
  		position: relative;
  		display: inline-block;

  		&:after {
		   	content: '';
		    display: block;
		    position: absolute;
		    right: 0;
		    bottom: -1px;
		    height: 1px;
		    width: 0;
		    background: transparent;
		    transition: width 250ms ease, background-color 0ms ease;

				@media (max-width:em($mobile-menu-switch-max)){
					display: none;
				}	
  		}

  		&:hover {
  			&:after {
			    width: 100%;
			    background: $red;  			
  			}
  		}
  	}
  }

  .text-under {
	  font-size: 15px;
	  letter-spacing: 0.115px;
	  line-height: 1;
  	padding-top: 4px;

  	@media (max-width:em(400px)){
  		display: none;
  	}
  }

  .content-container {
  	display: flex;
  	justify-content: space-between;
  	align-items: center;
  	flex: 1;

  	margin-left: 12px;
  	padding-left: 12px;  	
  	border-left: 2px solid $red;

  	font-size: 1em;

  	@media (max-width:em($mobile-menu-switch-max)){
  		display: none;
  	}
  }
}